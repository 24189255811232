import React from "react"
import { Helmet } from "react-helmet"
import { useContext } from "react"

import {
  Header,
  Footer,
  Title,
  BottomNavbar,
  CarouselPrimary,
  AccountContent,
  NoAuth,
  Loader,
} from "../components/index"

import SubTitle from "../components/Common/SubTitle/SubTitle"
import Container from "../components/Common/Container/Container"

import mockup from "../utils/data"

// Context
import UserContext from "../context/UserAuth"

const IndexPage = () => {
  const { isAuthenticated, isAccess, isLoading, userMetaData } = useContext(
    UserContext
  ) || {
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
    userMetaData: null,
  }
  return (
    <>
      <Helmet>
        <title>Esteekey - Account Page</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        {isLoading && <Loader />}
        {!isAuthenticated && <NoAuth />}
        {isAuthenticated && <AccountContent userData={userMetaData} />}
      </div>
      <Footer />
    </>
  )
}

export default IndexPage
